import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

class BenefitsSlider {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.slider = null;
        this.prevEl = this.root.querySelector('[data-benefits-slider-prev]');
        this.nextEl = this.root.querySelector('[data-benefits-slider-next]');
        return true;
    }

    init() {
        this.initSlider();
    }

    initSlider() {

        this.slider = new Swiper(this.root, {
            modules: [Pagination, Navigation],
            slidesPerView: 1,
            navigation: {
                prevEl: this.prevEl,
                nextEl: this.nextEl,
            },
            pagination: {
                el: '.js-slider-pagination',
                clickable: true,
                bulletClass: 'swiper__pagination-item',
                bulletActiveClass: 'is-active',
            },
        });

        this.slider.on('slideChangeTransitionEnd', () => {
            this.updateNavigationState();
        });

        this.updateNavigationState();
    }

    updateNavigationState() {
        if (this.slider.isBeginning) {
            this.prevEl.classList.add('is-end');
        } else {
            this.prevEl.classList.remove('is-end');
        }

        if (this.slider.isEnd) {
            this.nextEl.classList.add('is-end');
        } else {
            this.nextEl.classList.remove('is-end');
        }
    }
}

function initBenefitsSlider() {
    const sections = document.querySelectorAll('[data-benefits-slider]');
    if (!sections) return;

    sections.forEach(item => new BenefitsSlider(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initBenefitsSlider();
} else {
    document.addEventListener('DOMContentLoaded', initBenefitsSlider);
}
